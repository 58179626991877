/* eslint-disable import/no-cycle */
import * as React from 'react';
import { sanitizeHtmlString } from '@inwink/utils/methods/sanitizehtmlstring';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { Entities } from '@inwink/entities/entities';
import { EventAgendaBreakItem } from './items/bloc.eventagenda.breakitem';
import { EventAgendaSessionItem } from './items/bloc.eventagenda.sessionitem';
import { EventAgendaSessionPickerItem } from './items/bloc.eventagenda.sessionpickeritem';
import { EventAgendaStaticItem } from './items/bloc.eventagenda.staticitem';
import { States } from '../../../../services/services';
import { getTimeZoneDateDisplay } from '../../../../components/displaytimezonedate';

export function showLabel(lngcode, label): string {
    if (!label) return "";

    if (typeof label === "string") return label;

    return sanitizeHtmlString(label[lngcode] || "");
}

export interface IEventAgendaItemProps {
    id?: string;
    item: Entities.IEventAgendaDayItemConfiguration;
    day: any;
    itemIndex: number;
    event: States.IEventState;
    i18n: States.i18nState;
    user: States.IAppUserState;
    disableSessionLink: boolean;
    showDetailedSpeakers: boolean;
    speakertemplate?: string;
    speakerorder:any;
    sessiontemplate?:string;
    hideTimeLabel?: boolean;
    theme: VisualTheme.IBlocTheme;
    onItemClicked: (item: Entities.IEventAgendaDayItemConfiguration) => void;
    urlservice: States.IAppUrlContext;
    displayTime?: boolean;
    getTimeLabel?: () => string;
    completeTimeLabel?: boolean;
    datacontext?: any;
    page?: any;
    timeFormat: string;
}
export class EventAgendaItem extends React.Component<IEventAgendaItemProps, any> {
    constructor(props) {
        super(props);
        this.getTimeLabel = this.getTimeLabel.bind(this);
    }

    getTimeLabel() {
        // TODO : TRADS
        if (this.props.displayTime) {
            let endDate;
            const startDate = getTimeZoneDateDisplay(
                this.props.item?.startDate,
                this.props.event?.detail,
                this.props.i18n,
                this.props.timeFormat
            );
            if (this.props.item.endDate && this.props.completeTimeLabel) {
                endDate = getTimeZoneDateDisplay(
                    this.props.item?.endDate,
                    this.props.event?.detail,
                    this.props.i18n,
                    this.props.timeFormat
                );
            }
            return startDate + (endDate ? " - " + endDate : "");
        }
    }

    render() {
        if (this.props.item.kind === 'Session') {
            return <EventAgendaSessionItem {...this.props} id={this.props.item.id} getTimeLabel={this.getTimeLabel} />;
        } if (this.props.item.kind === 'SessionPicker') {
            return <EventAgendaSessionPickerItem {...this.props} id={this.props.item.id} getTimeLabel={this.getTimeLabel} />;
        } if (this.props.item.kind === 'Break') {
            return <EventAgendaBreakItem {...this.props} id={this.props.item.id} getTimeLabel={this.getTimeLabel} />;
        } if (this.props.item.kind === "Static") {
            return <EventAgendaStaticItem {...this.props} id={this.props.item.id} getTimeLabel={() => ""} />;
        }
        return <div className="empty" />;
    }
}
