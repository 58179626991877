import * as React from 'react';
import { DynLabel, AppTextLabel } from '../../../../../services/i18nservice';
import { IEventAgendaItemProps, showLabel } from '../bloc.eventagenda.item';
import { EventAgendaSessionItem } from './bloc.eventagenda.sessionitem';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import { Entities } from '@inwink/entities/entities';

export class EventAgendaSessionPickerItem extends React.Component<IEventAgendaItemProps, any> {
    render() {
        const item = this.props.item;
        const index = this.props.itemIndex;
        const day = this.props.day;
        let detail, items, actions;

        if (item.description) {
            detail = <div
                className="description"
                dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: showLabel(this.props.i18n.currentLanguageCode, item.description)
                }}></div>;
        }
        const itemstart = moment(item.startDate);
        const itemend = moment(item.endDate);
        const sessions = this.props.user.currentUser?.data?.registeredSessions
            && this.props.user.currentUser.data.registeredSessions.data
                .filter((rs) => rs.status == "Active")
                .map((rs) => {
                    const session = this.props.event.data.sessions.data.filter((s) => s.id == rs.sessionId)[0];
                    if (session && session.timeslots && session.timeslots.length) {
                        const slot = session.timeslots[0];
                        const start = moment(slot.startDate);
                        if (start.isSameOrAfter(itemstart) && start.isSameOrBefore(itemend)) {
                            return session;
                        }
                    }
                }).filter((s) => !!s).sort((a: Entities.ISession, b: Entities.ISession) => {
                    try {
                        const dateA = moment(a.timeslots[0].startDate);
                        const dateB = moment(b.timeslots[0].startDate);
                        if (dateA.isBefore(dateB)) return -1;
                        else if (dateB.isBefore(dateA)) return 1;
                        else return 0;
                    } catch (e){
                        console.error(e);
                        return 0;
                    }
                });

        if (sessions && sessions.length) {
            const sessionitems = sessions.map((s, idx) => {
                return <EventAgendaSessionItem {...this.props} key={s.id}
                    item={{
                        id: s.id,
                        title: s.title,
                        description: s.description,
                        startDate: s.timeslots[0].startDate,
                        endDate: s.timeslots[0].endDate,
                        kind: "Session",
                        display: 'ShowAlways'
                    }}
                    itemIndex={idx}
                    hideTimeLabel={true}
                />;
            });
            items = <div className="pickedsessions">{sessionitems}</div>;
        }
        if (!sessions || !sessions.length || !sessions.some(s => !!s.quota)) {
            const td = moment(day.day).format("YYYY-MM-DD");
            const ttf = encodeURIComponent(itemstart.format("HH:mm"));
            const ttt = encodeURIComponent(itemend.format("HH:mm"));
            const args = this.props.item && this.props.item.disableTimeSlotFilter
                ? ""
                : `?timeslotday=${td}&timeslottimefrom=${ttf}&timeslottimeto=${ttt}`;
            const actionLabel = this.props.item?.buttonLabel && this.props.item.buttonLabel[this.props.i18n.currentLanguageCode] ?
                <DynLabel component="span" i18n={item.buttonLabel} /> :
                <AppTextLabel i18n="eventagenda.addsession" />;
            actions = <div className="actions">
                <Link role="button" to={(this.props.item?.url
                    ? this.props.urlservice.pageUrl(this.props.item.url) : this.props.urlservice.pageUrl("sessions")) + args}>
                    {actionLabel}
                </Link>
            </div>;
        }
        return <div
            key={index}
            className="agendaitem sessionpicker bloc-accent2border"
            id={this.props.id ? "sessionpicker-" + this.props.id : null}
        >
            <div className="agendaitemcontent">
                <div className="itemhours bloc-extralightbg">
                    {this.props.getTimeLabel()}
                </div>
                <div className="agendaitemdetail bloc-lightborder">
                    <DynLabel component="div" className="bloc-accent title" i18n={item.title} />
                    {detail}
                    {items}
                    {actions}
                </div>
            </div>
        </div>;
    }
}