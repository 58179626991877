import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Entities } from '@inwink/entities/entities';
import { DynLabel } from '../../../../../services/i18nservice';
import { IEventAgendaItemProps, showLabel } from '../bloc.eventagenda.item';
import { ItemTemplate } from '../../../../../components/templates/itemtemplate';
import { sortItems } from '../../../../../components/dynamicpage/teaseritems.props';
import { EntityTemplateContextProvider } from '../../../../../components/entities/entitytemplatecontext';
import { SessionItemSpeakers } from '../../sessions/itemtemplate/sessionitemspeakers';
import { ItemTemplateProvider } from '../../../../../components/templates/itemtemplateprovider';

interface IEventAgendaSessionItemComponentState {
    session: Entities.ISession;
    template: any;
    fieldtemplate: any;
    personFieldTemplate: any;
}
class EventAgendaSessionItemComponent extends React.Component<IEventAgendaItemProps, IEventAgendaSessionItemComponentState> {
    constructor(props) {
        super(props);
        const sessionFieldTemplate = this.props.event.data.fieldtemplates
            .data.find((f) => f.entityName.toLowerCase() === "session");
        const personFieldTemplate = this.props.event.data.fieldtemplates
            .data.find((f) => f.entityName.toLowerCase() === "person");
        this.state = {
            session: this.props.event.data.sessions.data.find((s) => s.id === this.props.item.id),
            template: this.props.event.data.templates
                .data.find((t) => t.tinyUrl === this.props.sessiontemplate && t.contentType === "template"),
            fieldtemplate: sessionFieldTemplate,
            personFieldTemplate: personFieldTemplate,
        };
    }

    renderContent() {
        const item = this.props.item;
        // const day = this.props.day;
        let detail = null; let
            speakerItems = null;
        if (item.id) {
            const session = this.state.session;
            if (session) {
                if (session.speakers && Array.isArray(session.speakers) && session.speakers.length > 0) {
                    let speakers = session.speakers.map((s) => {
                        return this.props.event.data.speakers.data.find((sp) => sp.id === s.id);
                    }).filter((s) => {
                        if (!s) return false;
                        return true;
                    });

                    if (this.props.speakerorder) {
                        speakers = sortItems(speakers, this.props.i18n.currentLanguageCode, this.props.speakerorder);
                    }

                    if (speakers && speakers.length > 0) {
                        let template = this.props.showDetailedSpeakers
                            && this.props.speakertemplate
                            && this.props.event.data.templates.data.find(
                                (t) => t.tinyUrl === this.props.speakertemplate && t.contentType === "template"
                            );

                        if (template) {
                            if (template?.config?.template) {
                                template = template.config.template;
                            }
                            speakerItems = <EntityTemplateContextProvider fieldTemplate={this.state.personFieldTemplate}>
                                <div className="speakers detailed">
                                    {speakers.map((speaker) => {
                                        return <Link
                                            key={speaker.id}
                                            onClick={(e) => e.stopPropagation()}
                                            to={this.props.urlservice.pageUrl(`speaker/${speaker.id}`)}
                                        >
                                            <ItemTemplate
                                                key={speaker.id}
                                                data={speaker}
                                                datacontext={this.props.datacontext}
                                                template={template as any}
                                                page={this.props.page}
                                                theme={this.props.theme}
                                                user={this.props.user.currentUser}
                                                i18n={this.props.i18n}
                                                urlservice={this.props.urlservice}
                                            />
                                        </Link>;
                                    })}
                                </div>
                            </EntityTemplateContextProvider>;
                        } else {
                            speakerItems = <div className="speakers">
                                <SessionItemSpeakers
                                    theme={this.props.theme}
                                    datacontext={this.props.datacontext}
                                    user={this.props.user.currentUser as any}
                                    urlservice={this.props.urlservice}
                                    page={this.props.page}
                                    template={this.props.event.data.templates.data
                                        .find((t) => t.tinyUrl === this.props.speakertemplate
                                            && t.contentType === "template") as any}
                                    data={{ speakersData: speakers }}
                                    i18n={this.props.i18n}
                                />
                            </div>;
                        }
                    }
                }
            }
        }

        if (item.description) {
            detail = <div
                className="description"
                // eslint-disable-next-line @typescript-eslint/naming-convention
                dangerouslySetInnerHTML={{ __html: showLabel(this.props.i18n.currentLanguageCode, item.description) }}
            />;
        }

        return <div key={item && item.id} className="agendaitemcontent">
            <div className="itemhours bloc-extralightbg">
                {this.props.getTimeLabel()}
            </div>
            <div className="agendaitemdetail bloc-lightborder">
                <DynLabel component="div" className="bloc-accent title" i18n={item.title} />
                {detail}
                {speakerItems}
            </div>
        </div>;
    }

    getTemplate(templatename: string) {
        const template = this.state.template && this.state.template.config;
        if (template && template[templatename]) {
            return template[templatename];
        }
    }

    getTemplateProperties() {
        return null; // this.props.templateProperties;
    }

    renderTemplate() {
        let data;
        let template = this.state as any;
        const { session, fieldtemplate } = this.state;

        if (template && template.config && template.config.template) {
            template = template.config.template;
        }
        const timeLabel = !this.props.hideTimeLabel ? <div className="itemhours bloc-extralightbg">
            {this.props.getTimeLabel()}
        </div> : null;

        if (template) {
            data = session;
            if (!data) {
                data = this.props.item;
            }

            return <EntityTemplateContextProvider fieldTemplate={fieldtemplate}>
                <ItemTemplateProvider provider={this}>
                    <div className="agendaitemcontent">
                        {timeLabel}
                        <div className="agendaitemdetail bloc-lightborder">
                            <ItemTemplate
                                key={this.props.item.id}
                                data={data}
                                datacontext={this.props.datacontext}
                                template={template as any}
                                page={this.props.page}
                                theme={this.props.theme}
                                user={this.props.user.currentUser}
                                i18n={this.props.i18n}
                                urlservice={this.props.urlservice}
                            />
                        </div>
                    </div>
                </ItemTemplateProvider>
            </EntityTemplateContextProvider>;
        }
    }

    componentDidUpdate(prevProps: IEventAgendaItemProps) {
        if (prevProps.item?.id !== this.props.item?.id) {
            this.setState({
                session: this.props.event.data.sessions.data.find((s) => s.id === this.props.item.id)
            });
        }
    }

    render() {
        const item = this.props.item;
        const index = this.props.itemIndex;

        let onClick = null;
        if (!this.props.disableSessionLink && item.id && !item.disableSessionLink) {
            onClick = () => this.props.onItemClicked(item);
        }
        return <div
            key={index}
            id={this.props.id ? "session-" + this.props.id : null}
            className={"agendaitem session bloc-accentborder bloc-mediumbg" + (onClick ? " clickable" : "")}
            onClick={onClick}
        >
            {this.props.sessiontemplate
                ? this.renderTemplate()
                : this.renderContent()}
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        // datacontext: state.datacontext,
        page: state.page
    };
}
export const EventAgendaSessionItem: new (any) => React.Component<IEventAgendaItemProps, any> = connect(
    mapStateToProps,
    null
)(EventAgendaSessionItemComponent as any) as any;
