import * as React from 'react';
import { sanitizeHtmlString } from '@inwink/utils/methods/sanitizehtmlstring';
import { DynLabel } from '../../../../../services/i18nservice';
import type { IEventAgendaItemProps } from '../bloc.eventagenda.item';

export class EventAgendaBreakItem extends React.Component<IEventAgendaItemProps, any> {
    render() {
        const item = this.props.item;
        const index = this.props.itemIndex;
        // const day = this.props.day;
        const description = item && item.description && item.description[this.props.i18n.currentLanguageCode] ?
            <span
                // eslint-disable-next-line @typescript-eslint/naming-convention
                dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(item.description[this.props.i18n.currentLanguageCode]) }}
                className="agenda-desc"></span> :
            null;
        const breakBackgroundValue = (this.props.theme?.extraLightBg?.backgroundColor
            && this.props.theme?.lightBg?.backgroundColor ?
            "repeating-linear-gradient(45deg, " +
            this.props.theme.extraLightBg.backgroundColor + ", " +
            this.props.theme.extraLightBg.backgroundColor + " 5px, " +
            this.props.theme.lightBg.backgroundColor + " 5px, " +
            this.props.theme.lightBg.backgroundColor + " 10px)" : "");
        return <div
            key={index}
            id={this.props.id ? "break-" + this.props.id : null}
            className="agendaitem break" style={breakBackgroundValue ? { background: breakBackgroundValue } : null}>
            <div className="agendaitemcontent">
                <div className="itemhours bloc-extralightbg">
                    {this.props.getTimeLabel()}
                </div>
                <div className="agendaitemdetail bloc-lightborder">
                    <DynLabel component="div" className="bloc-accent title" i18n={item.title} />
                    <div className="description">{description}</div>
                </div>
            </div>
        </div>;
    }
}