import * as React from 'react';
import { DynLabel } from '../../../../../services/i18nservice';
import type { IEventAgendaItemProps } from '../bloc.eventagenda.item';
import { showLabel } from '../bloc.eventagenda.item';
import { connect } from 'react-redux';

class EventAgendaStaticItemComponent extends React.Component<IEventAgendaItemProps, any>{
    render() {
        return <div className="agendaitemcontent" id={this.props.id ? "static-" + this.props.id : null}>
            <div className="agendaitemdetail">
                <DynLabel component="div" className="bloc-accent title" i18n={this.props.item.title} />
                <div
                    className="static-content"
                    dangerouslySetInnerHTML={{
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        __html: showLabel(this.props.i18n.currentLanguageCode,
                            this.props.item.description)
                    }}>

                </div>
            </div>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        //datacontext: state.datacontext,
        page: state.page
    };
}

export const EventAgendaStaticItem: new (any) => React.Component<IEventAgendaItemProps, any> = connect(
    mapStateToProps, null)(EventAgendaStaticItemComponent as any) as any;
