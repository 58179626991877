import * as React from 'react';
import type { Entities } from '@inwink/entities/entities';
import type { IBlocEventAgendaProps } from './bloc.eventagenda';
import { getMoment } from '../../../../services/i18nservice';

interface IEventAgendaPagerProps extends IBlocEventAgendaProps {
    agendaItems: Entities.IEventAgendaConfiguration;
    dayFormat: string;
    updateFilter: (page: number) => void;
}

export function EventAgendaPager(props: IEventAgendaPagerProps) {
    let prev; let
        next;
    let day = props.blocState && props.blocState.days && props.blocState.days[props.blocState.currentPage] as any;
    if (!day) {
        return <div className="agendapager" />;
    }

    day = getMoment(props.i18n, day);

    if (props.blocState.currentPage > 0) {
        prev = <div className="daynav prev clickable" onClick={() => props.updateFilter(props.blocState.currentPage - 1)}>
            <div className="inwink-chevron-left" />
        </div>;
    } else {
        prev = <div className="daynav prev" />;
    }

    if (props.blocState.days && props.blocState.currentPage < props.blocState.days.length - 1) {
        next = <div className="daynav next clickable" onClick={() => props.updateFilter(props.blocState.currentPage + 1)}>
            <div className="inwink-chevron-right" />
        </div>;
    } else {
        next = <div className="daynav next" />;
    }

    return <div className="agendapager">
        {prev}
        <div className="day bloc-accent">{day.format(props.dayFormat)}</div>
        {next}
    </div>;
}
