/* eslint-disable import/no-cycle */
import * as React from 'react';
import * as moment from 'moment';
import * as assign from 'lodash/assign';
import * as merge from 'lodash/merge';
import { withRouter } from 'react-router-dom';
import { getPredicate } from '@inwink/expressions';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { parse } from '@inwink/utils/querystring';
import * as sortBy from 'lodash/sortBy';
import { IDynamicBlocProps, DynamicBloc } from '../../../../components/dynamicpage/common';
import { BlocTitle } from "../../../../components/dynamicpage/common.title";
import { getBlocTemplate } from '../../../../services/itemshelpers';
import { getMoment } from '../../../../services/i18nservice';
import { getEventAgenda } from '../../../../data/templates';
import { EventAgendaItem } from './bloc.eventagenda.item';
import { EventAgendaPager } from './bloc.eventagenda.pager';
import type { States } from '../../../../services/services';
import { ContentStyle } from '../../../../components/contentstyle';
import './bloc.eventagenda.less';
import { getTimeZoneDateDisplay } from '../../../../components/displaytimezonedate';
import { AppTextLabel } from '@inwink/i18n/apptextlabel';

export interface IBlocItemsListState {
    currentPage: number;
    pageSize: number;
    totalCount: number;
    pageCount: number;
    filters: any;
    itemtemplate: VisualTheme.IItemTemplate;
    loading: boolean;
    items: any[];
}

export interface IBlocEventAgendaDataState extends IBlocItemsListState {
    items: Entities.IAgendaItem[];
    days: moment.Moment[];
    agendaTemplate?: Entities.IEventAgendaConfiguration;
}

function getDays(startDate: string, endDate: string) {
    if (!startDate) return [];

    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);
    const days = [momentStartDate];
    let current = momentStartDate.clone();
    while (current.isBefore(momentEndDate, "day")) {
        current = current.clone().add(1, 'days');
        days.push(current);
    }
    return days;
}

export function blocEventAgendaData(page: States.ICurrentPageState, bdata: IBlocEventAgendaDataState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bloc: VisualTheme.IBlocContentTemplateBase, state: States.IAppState, dispatch: (action) => void) {
    const blocdata = bdata;
    const data = state.event.data;
    const tinyUrl = bloc.properties && bloc.properties.tinyUrl;
    blocdata.agendaTemplate = getEventAgenda(data, tinyUrl && tinyUrl !== "event-agenda" ? tinyUrl : null);
    let startDate: string = state.event?.detail?.startDate as string;
    let endDate: string = state.event?.detail?.endDate as string;
    let templateDays = blocdata.agendaTemplate && blocdata.agendaTemplate.days
        ? blocdata.agendaTemplate.days.map((d) => (d.day ? moment(d.day).startOf('day')
            .toISOString(true).split('.')[0] : null)).filter((t) => t != null) : [];
    if (bloc?.properties?.disableAutoPopulateWithSessions || templateDays?.length) {
        // ici on utilise en date de début et date de fin les dates du bloc agenda et non pas celles de l'événement
        if (templateDays.length >= 1) {
            templateDays = sortBy(templateDays, [(item) => new Date(item)]);
            startDate = templateDays[0];
            endDate = templateDays[templateDays.length - 1];
        }
    } else if (data?.sessions?.data && blocdata?.agendaTemplate?.days) {
        // mapper les sessions, si une session tombe sur un jour qui n'est pas dans l'agendatemplate
        // rajouter ce jour dans l'agenda template
        data.sessions.data.map((s) => {
            const day = s.timeslots && s.timeslots[0] && s.timeslots[0].startDate
                ? moment(s.timeslots[0].startDate).startOf('day').toISOString(true).split('.')[0] : null;
            if (day) {
                if (templateDays.indexOf(day) < 0) {
                    blocdata.agendaTemplate.days.push({
                        day,
                        openingStart: day,
                        openingEnd: day,
                        items: []
                    });
                }
            }
            return null;
        });
    }
    blocdata.days = getDays(startDate, endDate);
    blocdata.pageCount = blocdata.days.length;
    blocdata.currentPage = 0;

    if (page.pageargs && page.pageargs.location) {
        const query = parse(page.pageargs.location.search, null);
        if (query.page) {
            blocdata.currentPage = query.page - 1;
        }
    }

    blocdata.itemtemplate = getBlocTemplate(data, bloc);
}

export interface IBlocEventAgendaProps extends IDynamicBlocProps<IBlocEventAgendaDataState> {
    // networkingActions?: typeof networkingActions;
    history?: any;
}

export interface IBlocEventAgendaState {
    agendaItems: Entities.IEventAgendaConfiguration,
    currentDayAgendaItems: Entities.IEventAgendaDayConfiguration,
    disableGroupItemsByHour: boolean,
    disableSessionLink: boolean,
    disableDayHeader: boolean,
    dayFormat?: string,
    timeFormat?: string,
    isInlineCalendar?: boolean;
    groupByTimeSlot?: boolean;
}

class BlocEventAgendaComponent extends React.PureComponent<IBlocEventAgendaProps, IBlocEventAgendaState> {
    constructor(props: IBlocEventAgendaProps) {
        super(props);
        this.openEventAgenda = this.openEventAgenda.bind(this);
        this.itemClicked = this.itemClicked.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.state = this.getState();
    }

    getState = () => {
        let items: Entities.IEventAgendaConfiguration = null;
        const displays = this.props.template.properties.display as Entities.IEventAgendaDayItemDisplay[];

        if (this.props.blocState?.agendaTemplate?.days) {
            if (displays) {
                items = { days: [] };
                this.props.blocState.agendaTemplate.days.forEach((day) => {
                    const ims = day.items.filter((i) => (displays.indexOf(i.display) !== -1));
                    if (ims.length > 0) {
                        items.days.push({
                            day: day.day,
                            items: ims.filter((d) => {
                                if (this.props.template.properties.sessionsFilter && d) {
                                    const predicate = getPredicate(this.props.template.properties.sessionsFilter);
                                    const a = predicate(assign({}, this.props, d));
                                    return a;
                                }
                                return d;
                            }),
                            openingEnd: day.openingEnd,
                            openingStart: day.openingStart
                        });
                    }
                });
            } else {
                items = merge({}, this.props.blocState.agendaTemplate);
            }
        } else if (this.props.template?.properties?.agenda) {
            items = merge({}, this.props.template.properties.agenda);
        }

        if (items && this.props.blocState?.days) {
            this.props.blocState.days.filter((d) => {
                const isAlreadyInList = items?.days?.length && items.days.some((day) => {
                    return moment(day.day).isSame(d, 'day');
                });
                return !isAlreadyInList;
            }).forEach((dd) => {
                let d = dd;
                if (typeof d === "string") {
                    d = getMoment(this.props.i18n, d);
                }
                items.days.push({
                    day: d.format(),
                    items: [],
                    openingStart: d.clone().startOf('day').format(),
                    openingEnd: d.clone().startOf('day').format()
                });
            });
        }

        if (!this.props.template.properties
            || (this.props.template.properties && !this.props.template.properties.disableAutoPopulateWithSessions)) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let predicate = (s: Entities.ISession) => true;
            // uniquement les sessions avec un timeslot
            let sessions = this.props.event.data.sessions.data.filter((s) => {
                if (!s || !s.timeslots || !s.timeslots.length) return false;

                return true;
            });

            if (this.props.template.properties.sessionsFilterExpr) {
                predicate = getPredicate(this.props.template.properties.sessionsFilterExpr);
                sessions = sessions.filter((s) => {
                    return predicate(assign({}, this.props, { entity: s }));
                });
            } else if (this.props.template.properties.sessionsFilter) {
                predicate = getPredicate(this.props.template.properties.sessionsFilter);
                sessions = sessions.filter((s) => {
                    const a = predicate(assign({}, this.props, s));
                    return a;
                });
            }

            sessions.map((s) => {
                const slot = s.timeslots[0];
                const startDate = slot?.startDate;
                const endDate = slot?.endDate;
                return {
                    id: s.id,
                    title: s.title,
                    description: s.description,
                    startDate: startDate,
                    endDate: endDate,
                    kind: 'Session'
                } as Entities.IEventAgendaDayItemConfiguration;
            }).forEach((a) => {
                if (items && items.days) {
                    const dd = items.days.filter((d) => d.day && a.startDate && moment(d.day).isSame(moment(a.startDate), 'day'));
                    if (dd.length === 1) {
                        dd[0].items.push(a);
                    }
                }
            });
        }

        let agendatItems; let
            agendatItem;
        if (items && items.days) {
            // Ici on va filtrer les jours qui n'ont pas de sessions associés et ensuite on va les trier par date de début. #47520
            items.days = items.days.filter((day) => {
                return day.items?.length;
            }).sort((a, b) => {
                const startA = moment(a.openingStart);
                const startB = moment(b.openingStart);
                return startA.diff(startB);
            });
            items.days.forEach((d) => {
                d.items.sort((a, b) => {
                    const startA = moment(a.startDate);
                    const startB = moment(b.startDate);
                    return startA.diff(startB, 'minute');
                });
            });

            agendatItems = items && items.days
                ? items.days.filter((d) => moment(d.day)
                    .isSame(this.props.blocState.days[this.props.blocState.currentPage], 'day'))
                : [];
            agendatItem = agendatItems.length === 1 ? agendatItems[0] : null;
        }

        return {
            agendaItems: items,
            currentDayAgendaItems: agendatItem,
            disableGroupItemsByHour: this.props.template?.properties?.disableGroupItemsByHour || false,
            disableSessionLink: this.props.template?.properties?.disableSessionLink || false,
            disableDayHeader: this.props.template?.properties?.disableDayHeader || false,
            dayFormat: this.props.template?.properties?.dayFormat || 'LL',
            timeFormat: this.props.template?.properties?.timeFormat || 'HH:mm',
            isInlineCalendar: this.props.template?.properties?.isInlineCalendar || false,
            groupByTimeSlot: this.props.template?.properties?.groupByTimeSlot || false
        };
    };

    itemClicked(item: Entities.IEventAgendaDayItemConfiguration) {
        if (item && item.kind === 'Static') return;
        if (item && item.kind === 'Session') {
            this.props.history.push(this.props.urlservice.pageUrl(`session/${item.id}`));
        }
    }

    renderItemsGroupByHour(currentDay?: Entities.IEventAgendaDayConfiguration) {
        if (currentDay || this.state.currentDayAgendaItems) {
            const borderBottomValue = this.props.theme?.lightBg?.backgroundColor
                ? `1px dashed ${this.props.theme.lightBg.backgroundColor}` : "";
            const itemsByHour: Entities.IEventAgendaDayItemConfiguration[][] = [];
            let currentItemsByHour: Entities.IEventAgendaDayItemConfiguration[] = [];

            let day;
            if (currentDay) day = currentDay;
            else day = this.state.currentDayAgendaItems;
            if (!day) return null;

            day.items.forEach((agendaItem) => {
                if (currentItemsByHour.length === 0 || (currentItemsByHour.length > 0
                    && !moment(currentItemsByHour[0].startDate).isSame(agendaItem.startDate, 'hour'))) {
                    if (currentItemsByHour.length > 0) {
                        itemsByHour.push(currentItemsByHour);
                    }
                    currentItemsByHour = [];
                }
                currentItemsByHour.push(agendaItem);
            });

            if (currentItemsByHour.length > 0) {
                itemsByHour.push(currentItemsByHour);
            }

            return itemsByHour.map((is, index) => {
                let items = is;
                // on met le picker en bas
                if (this.state.groupByTimeSlot) {
                    items = items.sort((a, b) => (b.kind === "SessionPicker" ? -1 : 1));
                }
                // on trie d'abord par date, ensuite par nom
                items = sortBy(items, [(item) => new Date(item.startDate),
                    (item) => item?.title && item.title[this.props.i18n.currentLanguageCode]]);
                const renderedItems = items.map((item, itemIndex) => {
                    let shouldDisplayTime = true;
                    if (this.state.groupByTimeSlot) {
                        if (itemIndex > 0) {
                            shouldDisplayTime = !(items[itemIndex - 1].startDate === item.startDate
                                && items[itemIndex - 1].endDate === item.endDate);
                        }
                    }

                    return <EventAgendaItem
                        key={itemIndex}
                        day={day}
                        item={item}
                        displayTime={shouldDisplayTime}
                        timeFormat={this.state.timeFormat}
                        itemIndex={itemIndex}
                        datacontext={this.props.datacontext}
                        event={this.props.event}
                        user={this.props.user}
                        i18n={this.props.i18n}
                        theme={this.props.theme}
                        sessiontemplate={this.props.template?.properties?.sessiontemplate}
                        speakertemplate={this.props.template?.properties?.speakertemplate}
                        speakerorder={this.props.template?.properties?.speakerorder}
                        showDetailedSpeakers={this.props.template?.properties?.showSpeakersDetail}
                        onItemClicked={this.itemClicked}
                        disableSessionLink={this.state.disableSessionLink}
                        completeTimeLabel={this.props.template?.properties?.completeTimeLabel !== undefined
                            ? !!this.props.template.properties.completeTimeLabel : true}
                        urlservice={this.props.urlservice}
                    />;
                });
                const groupLabel = items[0].startDate
                    ? <div className="hour bloc-extralightbg bloc-accent">
                        {
                            getTimeZoneDateDisplay(
                                items[0].startDate,
                                this.props.event?.detail,
                                this.props.i18n,
                                this.state.timeFormat
                            )
                        }
                    </div> : null;
                return <div key={index} className="group" style={borderBottomValue ? { borderBottom: borderBottomValue } : null}>
                    {groupLabel}
                    <div className="agendaitemswrapper">{renderedItems}</div>
                </div>;
            });
        }
        return null;
    }

    renderItemsInline() {
        if (this.state.agendaItems.days) {
            return this.state.agendaItems.days.map((day, key) => {
                // TODO : TRADS
                // const daylabel = {
                //     fr: "Jour",
                //     en: "Day"
                // };
                const daylabel = <AppTextLabel component="h4" className="day" i18n="agenda.daylabel" />;
                let daycontent;
                if (this.state.disableGroupItemsByHour) {
                    daycontent = <div className="groups stdgroups bloc-lightborder">
                        {this.renderItems(day)}
                    </div>;
                } else {
                    daycontent = <div className="groups hourgroups bloc-lightborder">
                        {this.renderItemsGroupByHour(day)}
                    </div>;
                }
                const date = getMoment(this.props.i18n, day.day).format(this.state.dayFormat);
                return <div className="inline-day" key={key}>
                    <div className="day-label">
                        <h4 className="bloc-accent">{daylabel} {key + 1} : </h4>
                        <span className="text"> {date}</span>
                    </div>
                    {daycontent}
                </div>;
            });
        }

        return null;
    }

    renderItems(currentDay?: Entities.IEventAgendaDayConfiguration) {
        if (currentDay || this.state.currentDayAgendaItems) {
            let day;
            if (currentDay) day = currentDay;
            else day = this.state.currentDayAgendaItems;
            if (!day) {
                return null;
            }
            return <div className="itemperline">
                {day.items.map((item, index) => {
                    let shouldDisplayTime = true;
                    if (this.state.groupByTimeSlot) {
                        if (index > 0) {
                            shouldDisplayTime = !(day.items[index - 1].startDate === item.startDate
                                && day.items[index - 1].endDate === item.endDate);
                        }
                    }

                    return <EventAgendaItem
                        key={index}
                        day={day}
                        item={item}
                        itemIndex={index}
                        event={this.props.event}
                        user={this.props.user}
                        i18n={this.props.i18n}
                        datacontext={this.props.datacontext}
                        speakertemplate={this.props.template?.properties?.speakertemplate}
                        speakerorder={this.props.template?.properties?.speakerorder}
                        sessiontemplate={this.props.template?.properties?.sessiontemplate}
                        theme={this.props.theme}
                        showDetailedSpeakers={this.props.template?.properties?.showSpeakersDetail}
                        onItemClicked={this.itemClicked}
                        displayTime={shouldDisplayTime}
                        timeFormat={this.state.timeFormat}
                        completeTimeLabel={this.props.template?.properties?.completeTimeLabel !== undefined
                            ? !!this.props.template.properties.completeTimeLabel : true}
                        disableSessionLink={this.state.disableSessionLink}
                        urlservice={this.props.urlservice}
                    />;
                })}
            </div>;
        }

        return null;
    }

    openEventAgenda() {
        this.props.history.push(this.props.urlservice.pageUrl("eventagenda"));
    }

    updateFilters(currentPage) {
        const agendatItems = this.state.agendaItems && this.state.agendaItems.days
            ? this.state.agendaItems.days.filter((d) => moment(d.day).isSame(this.props.blocState.days[currentPage], 'day')) : [];
        const agendatItem = agendatItems.length === 1 ? agendatItems[0] : null;

        this.setState(assign({}, this.props.blocState, {
            currentPage: currentPage,
            currentDayAgendaItems: agendatItem
        }));

        this.props.history.replace(this.props.urlservice.pageUrl(`eventagenda?page=${currentPage + 1}`));

        this.props.update({
            currentPage: currentPage
        });
    }

    componentDidUpdate(prevProps: IBlocEventAgendaProps) {
        const query = parse(this.props.location.search, null);

        const pageindex = query && query.page ? query.page - 1 : 0;

        if ((this.props.blocState && pageindex !== this.props.blocState.currentPage)) {
            this.updateFilters(pageindex);
        }
        if (prevProps?.template?.properties !== this.props?.template?.properties) {
            const state = this.getState();
            this.setState(state);
        }
    }

    render() {
        let content = null;
        let agendaPager = null;
        let template;
        let itemstyle;

        if (this.props.template && this.props.template.properties && this.props.template.properties.sessiontemplate) {
            template = this.props.event.data.templates.data
                .find((t) => t.tinyUrl === this.props.template.properties.sessiontemplate && t.contentType === "template");
            if (template && template.config && template.config.customCSS) {
                itemstyle = <ContentStyle
                    css={template.config.customCSS}
                    blocid={this.props.bloctemplate.id}
                    contentid={this.props.template.id}
                    theme={this.props.theme}
                />;
            }
        }

        if (this.props.blocState && this.state.agendaItems?.days?.length > 0) {
            if (!this.state.disableDayHeader && !this.state.isInlineCalendar) {
                agendaPager = <EventAgendaPager
                    {... this.props}
                    agendaItems={this.state.agendaItems}
                    dayFormat={this.state.dayFormat}
                    updateFilter={this.updateFilters}
                />;
            }

            let onclick;
            if (this.props.template.properties.linkToEventAgenda) {
                onclick = this.openEventAgenda;
            }

            if (this.state.isInlineCalendar) {
                const days = this.renderItemsInline();

                content = <div className={onclick ? "clickable" : ""} onClick={onclick}>
                    {days}
                </div>;
            } else if (this.state.disableGroupItemsByHour) {
                content = <div className={onclick ? "clickable" : ""} onClick={onclick}>
                    <div className="groups stdgroups bloc-lightborder">
                        {this.renderItems()}
                    </div>
                </div>;
            } else {
                content = <div className={onclick ? "clickable" : ""} onClick={onclick}>
                    <div className="groups hourgroups bloc-lightborder">
                        {this.renderItemsGroupByHour()}
                    </div>
                </div>;
            }
        } else {
            return null;
        }
        return <DynamicBloc {...this.props} className="bloc-eventagenda">
            {itemstyle}
            <BlocTitle {...this.props} className="bloc-header" />
            <div className="bloc-content">
                {agendaPager}
                {content}
            </div>
        </DynamicBloc>;
    }
}

export const BlocEventAgenda: new (props)
=> React.Component<any, any> = withRouter(BlocEventAgendaComponent as any) as any;
